import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {
  PageContainer,
  ExpandCollapseButton,
  buttonOnBlueSx,
  mergeSxs
} from '@hconnect/uikit/src/lib2'
import React, {FC, Fragment, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapseLocationItem} from '../components/CollapseLocationItem'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DataInfo} from '../components/DataInfo'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {useEquipmentProductionVolumes} from '../hooks/useEquipmentProductionVolumes'
import {useEquipmentStatus} from '../hooks/useEquipmentStatus'
import {useHierarchyData} from '../hooks/useHierarchy'
import {PlantEquipmentDataProvider} from '../hooks/usePlantsEquipmentStatus'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {HierarchyNode, HierarchyNodeType} from '../types'

export const KilnMillStatus: FC = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {from, to} = useTimeRange()

  const timeRangeLoaded = useStatusInitialTimeRange()

  const {
    data: equipmentStatusData = {},
    refetch: equipmentStatusRefetch,
    isLoading: equipmentStatusIsLoading,
    isError: isEquipmentStatusError,
    isRefetching: isEquipmentStatusRefetching
  } = useEquipmentStatus(
    {
      startDate: from.toJSON(),
      endDate: to.toJSON()
    },
    HierarchyNodeType.Global,
    {enabled: timeRangeLoaded}
  )

  const {
    data: equipmentProductionVolumesData = {},
    refetch: equipmentProductionVolumesRefetch,
    isLoading: equipmentProductionVolumesIsLoading,
    isError: isEquipmentProductionVolumesError,
    isRefetching: isEquipmentProductionVolumesRefetching
  } = useEquipmentProductionVolumes(
    {
      startDate: from.toJSON(),
      endDate: to.toJSON()
    },
    HierarchyNodeType.Global,
    {enabled: timeRangeLoaded}
  )

  const hierarchy = useHierarchyData()

  const areaList: string[] = useMemo(
    () =>
      hierarchy?.children.reduce((acc: string[], hierarchyNode: HierarchyNode) => {
        if (hierarchyNode.isAllowed) acc.push(hierarchyNode.id)
        return acc
      }, []) || [],
    [hierarchy]
  )

  const [expandedAreas, toggleExpandedAreas] = useExpandedGroups(
    areaList,
    'kiln-mill-area-expanded'
  )

  React.useEffect(() => {
    if (
      !equipmentStatusIsLoading &&
      !equipmentProductionVolumesIsLoading &&
      expandedAreas.length !== areaList?.length
    )
      toggleExpandedAreas('all')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentStatusIsLoading, equipmentProductionVolumesIsLoading])

  if (!timeRangeLoaded) {
    return <Fragment />
  }

  return (
    <PageContainer>
      <StatusPageHeader
        title={t(`${performancePrefix}.kilnStatus.kilnAndMillPageTitle`)}
        filterComponent={
          <>
            <DateTimeRangePicker type="status" disableCustomSelect />
            <ExpandCollapseButton
              sx={mergeSxs(buttonOnBlueSx, {
                maxHeight: 58,
                width: {xs: '100%', sm: '150px'}
              })}
              collapseLabel={t(`${performancePrefix}.button.collapseAll`)}
              expandLabel={t(`${performancePrefix}.button.expandAll`)}
              expanded={expandedAreas.length === areaList?.length}
              onClick={() => toggleExpandedAreas('all')}
              data-test-id="chevron-open-all-button"
            />
          </>
        }
        afterTitle={<DataInfo />}
      />

      <DataContentWrapper
        isLoading={equipmentStatusIsLoading || equipmentProductionVolumesIsLoading}
        data={hierarchy?.children}
        retryFunction={() => {
          if (isEquipmentStatusError) void equipmentStatusRefetch()
          if (isEquipmentProductionVolumesError) void equipmentProductionVolumesRefetch()
        }}
        isError={isEquipmentStatusError || isEquipmentProductionVolumesError}
        isRefetching={isEquipmentStatusRefetching || isEquipmentProductionVolumesRefetching}
      >
        <PlantEquipmentDataProvider
          plantEquipmentStatuses={equipmentStatusData}
          plantEquipmentProductionVolumes={equipmentProductionVolumesData}
        >
          {hierarchy?.children?.map((hierarchyNode, index) => (
            <CollapseLocationItem
              key={`kiln-status-collapse-item-${index}`}
              handleOpen={toggleExpandedAreas}
              isOpen={expandedAreas.includes(hierarchyNode.id)}
              index={index}
              location={hierarchyNode}
            />
          ))}
        </PlantEquipmentDataProvider>
      </DataContentWrapper>
    </PageContainer>
  )
}
