import {ActionItemDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../shared/consts'
import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'get-action-items'

type Params = {
  plantId: string
  actionId: string
}

export const usePomAction = (
  {plantId, actionId}: Params,
  options: UseQueryOptions<ActionItemDto, AxiosError, ActionItemDto> = {}
) => {
  const axiosInstance = useAxios()
  const path = `${ApiPath.POM_QUESTIONNARIES}/${plantId}/actions/${actionId}`

  return useQuery<ActionItemDto, AxiosError, ActionItemDto>({
    queryKey: [QueryKey, plantId, actionId],
    queryFn: async () => {
      const response = await axiosInstance.get<ActionItemDto>(path)

      return response.data
    },
    ...options
  })
}
