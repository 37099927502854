import {Box, LinearProgress, Paper, SxProps, Theme, Typography} from '@mui/material'
import classNames from 'classnames'
import React, {ReactNode} from 'react'

export type ContentMode =
  | 'fullParentHeight'
  | 'max100PercentOfParentHeight'
  | 'contentDependentHeight'

type Props = {
  className?: string
  'data-test-id'?: string
  beforeTitle?: ReactNode
  title?: ReactNode
  afterTitle?: ReactNode
  mode: ContentMode
  minHeight?: string
  isLoading?: boolean
  bodyWithPadding?: boolean
  sx?: SxProps<Theme>
  children?: ReactNode
}

/**
 * for mode 'fullParentHeight' & 'max100PercentOfParentHeight' to work properly make sure the parent has a defined height
 */
export const ContentBox: React.FC<Props> = (props) => {
  const paperSX = {
    fullParentHeight: {height: '100%'},
    max100PercentOfParentHeight: {flexShrink: 1, overflow: 'hidden'},
    contentDependentHeight: {}
  }[props.mode]

  const bodySX = {
    fullParentHeight: {flexGrow: 1},
    max100PercentOfParentHeight: {flexShrink: 1},
    contentDependentHeight: {}
  }[props.mode]

  return (
    <Paper
      className={classNames(props.className)}
      data-test-id={props['data-test-id']}
      sx={{
        minHeight: props.minHeight,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ...paperSX,
        ...props.sx
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          paddingX: 3,
          paddingY: 1,
          minHeight: 70,
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexShrink: 0,

          '& $grow': {
            width: '100%'
          },
          '& $growOrScroll': {
            overflowX: 'auto',
            width: '100%'
          },

          '& $shrink': {
            maxWidth: '100%'
          },
          '& $shrinkOrScroll': {
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100%'
          },

          '& $auto': {
            width: 'auto',
            overflowX: 'auto'
          }
        }}
      >
        {props.beforeTitle && (
          <Box component="span" sx={{flexShrink: 0}}>
            {props.beforeTitle}
          </Box>
        )}
        <Typography variant={'h3'} sx={{fontSize: 18, flexGrow: 1, overflow: 'hidden'}}>
          {props.title}
        </Typography>
        {props.afterTitle && (
          <Box component="span" sx={{flexShrink: 0}}>
            {props.afterTitle}
          </Box>
        )}
      </Box>

      {/* TODO prevent the body from jumping, when changing the loading state */}
      {props.isLoading && <LinearProgress />}

      <Box px={props.bodyWithPadding ? 3 : 0} pb={props.bodyWithPadding ? 3 : 0} sx={bodySX}>
        {props.children}
      </Box>
    </Paper>
  )
}
