import {getEquipmentLabel} from '@hconnect/common/components/shiftEventLabels'
import {useMainEquipmentFilterOptionsSearch} from '@hconnect/common/hooks'
import {EquipmentData} from '@hconnect/common/types'
import {useDebounce} from '@react-hook/debounce'

import {FilterSearchAutocomplete, FilterSearchAutocompleteProps} from '../../../shared/components'
import {SEARCH_DELAY_IN_MS} from '../../../shared/consts'

export const TaskMainEquipmentSearchFilter = <TName extends string>(
  props: Omit<
    FilterSearchAutocompleteProps<TName, EquipmentData>,
    'options' | 'getOptionLabel' | 'getOptionValue' | 'isOptionEqualToValue' | 'searchTerm'
  > & {
    plantId: string
  }
) => {
  const [debouncedSearchText, setDebouncedSearchTerm] = useDebounce('', SEARCH_DELAY_IN_MS)

  const {data: suggestions, isLoading} = useMainEquipmentFilterOptionsSearch(
    props.plantId,
    debouncedSearchText
  )

  return (
    <FilterSearchAutocomplete<TName, EquipmentData>
      {...props}
      options={suggestions}
      getOptionLabel={getEquipmentLabel}
      getOptionValue={(option) => option.id}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.idType === value.idType
      }
      isLoading={isLoading}
      searchTerm={debouncedSearchText}
      onSearchChange={setDebouncedSearchTerm}
    />
  )
}
