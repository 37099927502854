import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

import {DataContentWrapper} from '../../../shared/components'
import {TaskDetailsCard, PlantActionDetails} from '../../components'
import {usePomAction} from '../../hooks'
import {HProduceTask} from '../../types'

type Props = {
  task: HProduceTask
  onClose: () => void
  timezone: string
  translationPrefix?: string
}

export const PlantActionDetailsContainer = ({
  task,
  timezone,
  translationPrefix,
  onClose
}: Props) => {
  const {t} = useTranslation()
  const {data, isLoading, isError, refetch, isRefetching} = usePomAction({
    plantId: task.plantId,
    actionId: task.externalId
  })

  return (
    <DataContentWrapper
      data={data}
      isLoading={isLoading}
      isError={isError}
      retryFunction={refetch}
      isRefetching={isRefetching}
      translationPrefix={translationPrefix}
      noDataSettings={{
        noDataMessage: t(getTranslationKey('task.message.taskNotFound', translationPrefix)),
        onActionClick: () => onClose()
      }}
    >
      {data && (
        <TaskDetailsCard
          item={task}
          timezone={timezone}
          translationPrefix={translationPrefix}
          onClose={onClose}
        >
          <PlantActionDetails
            action={data}
            timezone={timezone}
            translationPrefix={translationPrefix}
          />
        </TaskDetailsCard>
      )}
    </DataContentWrapper>
  )
}
