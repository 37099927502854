import {Task} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import moment from 'moment'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers'
import {useAxios} from '../../../shared/hooks'

const QueryKey = 'shift-task'

type UseShiftTaskOptions = UseQueryOptions<Task | undefined, AxiosError>

type Params = {
  plantId: string
  externalId: string
}

export const useShiftTask = ({plantId, externalId}: Params, options?: UseShiftTaskOptions) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const path = `${ApiPath.SHIFT_HANDOVER}/${plantId}/events/${externalId}`

  return useQuery<Task | undefined, AxiosError>({
    queryKey: [QueryKey, externalId, plantId],
    queryFn: async ({signal}) => {
      const {data} = await axiosInstance.get<Task>(path, {signal})

      return mapTask(data)
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}

const mapTask = (task?: Task) => {
  if (!task) return

  return {
    ...task,
    repetitionInfo: mapTaskRepetition(task.repetitionInfo)
  }
}

// TODO: workaround, replace this mapper with proper extracted Task types into common
const mapTaskRepetition = (repetitionInfo: Task['repetitionInfo']): Task['repetitionInfo'] => {
  if (!repetitionInfo) return undefined

  return {
    ...repetitionInfo,
    ...('startDate' in repetitionInfo
      ? {
          startDate: moment.utc(repetitionInfo.startDate),
          ...(repetitionInfo.endDate && {
            endDate: moment.utc(repetitionInfo.endDate)
          })
        }
      : {})
  }
}
