import {type TaskChecklist} from '@hconnect/common/types'
import {type AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router'

import {ApiPath} from '../../../shared/consts'
import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {type HProduceTask} from '../../types'

export const TaskChecklistsQueryKey = 'task-checklists'

type TaskChecklistsParams = Pick<HProduceTask, 'externalId' | 'plantId'>

export const useTaskChecklists = (
  {plantId, externalId: taskId}: TaskChecklistsParams,
  options?: UseQueryOptions<TaskChecklist[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const path = `${ApiPath.HROC_CHECKLISTS}/${plantId}/checklists/for-task/${taskId}`

  return useQuery<TaskChecklist[], AxiosError>({
    queryKey: [TaskChecklistsQueryKey, plantId, taskId],
    queryFn: async () => {
      const response = await axiosInstance.get<TaskChecklist[]>(path)
      return response.data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
