import {formatNumber} from '@hconnect/common/utils'
import {DateRange} from '@hconnect/uikit/src/common'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {AxiosError} from 'axios'
import {sortBy, findIndex} from 'lodash'
import React, {Fragment, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {getBarTimeFormattedLabel, getTimeStep} from '../../helpers'
import {useTimeRange} from '../../hooks/useTimeRange'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {
  AggregatedStoppage,
  AggregatedStoppageCodeStatistics,
  IssueGroupingKey,
  StoppageCode,
  StoppageCodeStatistic
} from '../../types'
import {DataContentWrapper} from '../DataContentWrapper'

type ColumnData = {
  key: string
  label: string | ReactNode
  width?: string
}

type Props = {
  stoppageConfig?: StoppageCode[]
  stoppageCodeStatsQuery: UseQueryResult<AggregatedStoppageCodeStatistics, AxiosError>
  sortedStoppages: AggregatedStoppage[]
  topIssuesGroupingKey: IssueGroupingKey
  timezone: string
}

type ColumnsConfig = {key: string; label: string}[]
const commonColumns: ColumnsConfig = [
  {key: 'stoppage-count-incident', label: 'stoppageCountIncident'},
  {key: 'stoppage-duration-incident', label: 'stoppageDurationIncident'},
  {key: 'stoppage-count-circumstance', label: 'stoppageCountCircumstance'},
  {key: 'stoppage-duration-circumstance', label: 'stoppageDurationCircumstance'}
]
const otherColumns = {
  stoppageCode: {key: 'code', label: 'stoppageCode'},
  mainEquipment: {key: 'main-equip', label: 'mainEquipment'},
  functionalLocation: {key: 'functional-location', label: 'functionalLocation'},
  totalStoppage: {key: 'total-stoppage', label: 'totalStoppage'},
  date: {key: 'date', label: 'date'}
}

const columns: Record<IssueGroupingKey, {key: string; label: string}[]> = {
  [IssueGroupingKey.STOPPAGE_CODE]: [
    otherColumns.stoppageCode,
    otherColumns.mainEquipment,
    otherColumns.functionalLocation,
    otherColumns.totalStoppage,
    ...commonColumns
  ],
  [IssueGroupingKey.MAIN_EQUIPMENT]: [
    otherColumns.mainEquipment,
    otherColumns.stoppageCode,
    otherColumns.functionalLocation,
    otherColumns.totalStoppage,
    ...commonColumns
  ],
  [IssueGroupingKey.EQUIPMENT_NUMBER]: [
    otherColumns.functionalLocation,
    otherColumns.stoppageCode,
    otherColumns.mainEquipment,
    otherColumns.totalStoppage,
    ...commonColumns
  ],
  [IssueGroupingKey.TIME_INTERVAL]: [
    otherColumns.date,
    otherColumns.stoppageCode,
    otherColumns.mainEquipment,
    otherColumns.functionalLocation,
    otherColumns.totalStoppage,
    ...commonColumns
  ]
}

export const StoppageStatsTable: React.FC<Props> = ({
  stoppageConfig = [],
  topIssuesGroupingKey,
  stoppageCodeStatsQuery,
  sortedStoppages,
  timezone
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const dateRange: DateRange<Date> = useTimeRange()
  const timeStep = getTimeStep(dateRange, timezone)
  const {
    data: stoppageCodeStats = {},
    isLoading,
    isError,
    refetch,
    isRefetching
  } = stoppageCodeStatsQuery

  const columnsData = useMemo(() => {
    const rows: ColumnData[][] = []
    sortedStoppages.forEach(({key}) => {
      const stoppageCodeStatistic: StoppageCodeStatistic = stoppageCodeStats[key]
      if (stoppageCodeStatistic) {
        const column = [
          ...(topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL
            ? [
                {
                  key: 'date',
                  label: getBarTimeFormattedLabel({
                    dateUtc: key,
                    step: timeStep,
                    timezone,
                    language
                  })
                }
              ]
            : []),
          {
            key: 'code',
            label: (
              <>
                {stoppageCodeStatistic.stoppageCodes.map((stoppageCode) => {
                  const stoppageCodeInfo: StoppageCode | undefined = stoppageConfig.find(
                    ({code}) => code === stoppageCode
                  )
                  return (
                    <Fragment key={stoppageCode}>
                      <Typography variant="subtitle1">{stoppageCodeInfo?.text}</Typography>
                      <Typography variant="caption">{stoppageCode}</Typography>
                    </Fragment>
                  )
                })}
              </>
            )
          },
          {
            key: 'main-equip',
            label: (
              <>
                {stoppageCodeStatistic.mainEquipments.map((equipment) => (
                  <Typography key={equipment.id}>{equipment.text}</Typography>
                ))}
              </>
            )
          },

          {
            key: 'functional-location',
            label: (
              <>
                {stoppageCodeStatistic.equipments
                  ? stoppageCodeStatistic.equipments.map((equipment) => (
                      <Typography key={equipment.id}>{equipment.text}</Typography>
                    ))
                  : '-'}
              </>
            )
          },
          {
            key: 'total-stoppage',
            label: formatNumber(stoppageCodeStatistic.hoursStopped, language)
          },
          {
            key: 'stoppage-count-incident',
            label: stoppageCodeStatistic.incidentStoppagesCount,
            width: '50px'
          },
          {
            key: 'stoppage-duration-incident',
            label: formatNumber(stoppageCodeStatistic.incidentHoursStopped, language),
            width: '50px'
          },
          {
            key: 'stoppage-count-circumstance',
            label: stoppageCodeStatistic.circumstanceStoppagesCount,
            width: '50px'
          },
          {
            key: 'stoppage-duration-circumstance',
            label: formatNumber(stoppageCodeStatistic.circumstanceHoursStopped, language),
            width: '50px'
          }
        ]

        const sortedColumns = sortBy<ColumnData>(column, ({key}: ColumnData) => {
          const columnConfig: ColumnsConfig = columns[topIssuesGroupingKey]
          return findIndex(columnConfig, {key})
        })
        rows.push(sortedColumns)
      }
    })
    return rows
  }, [
    sortedStoppages,
    stoppageCodeStats,
    topIssuesGroupingKey,
    timeStep,
    language,
    timezone,
    stoppageConfig
  ])

  return (
    <DataContentWrapper<AggregatedStoppageCodeStatistics>
      isLoading={isLoading}
      data={stoppageCodeStats}
      retryFunction={() => void refetch()}
      isError={isError}
      isRefetching={isRefetching}
    >
      <Paper>
        <TableContainer component="div" data-test-id="statistic-table">
          <Table>
            <TableHead>
              <TableRow>
                {columns[topIssuesGroupingKey].map((column) => (
                  <TableCell key={column.key} data-test-id={`stats-header-col-${column.key}`}>
                    {t(`${performancePrefix}.downtime.label.${column.label}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {columnsData.length ? (
                columnsData.map((row, index) => {
                  return (
                    <TableRow key={index} data-test-id={`stat-row-${index}`}>
                      {row.map(({key, label, ...rest}) => {
                        return (
                          <TableCell {...rest} key={key} data-test-id={`stats-col-${index}-${key}`}>
                            {label}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns[topIssuesGroupingKey].length}
                    data-test-id="table-desc-empty"
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    {t(`${performancePrefix}.downtime.label.noData`)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </DataContentWrapper>
  )
}
