import {PageContainer} from '@hconnect/uikit/src/lib2'
import {useParams} from 'react-router'

import {DataContentWrapper} from '../components/DataContentWrapper'
import {DefaultStoppageDetails} from '../containers/DefaultStoppageDetails'
import {EquipmentStoppageDetails} from '../containers/EquipmentStoppageDetails'
import {usePlantStoppages} from '../hooks/usePlantStoppages'
import {IssueGroupingKey} from '../types'

type PathParameter = {
  plantId: string
  groupId: string
  groupType: IssueGroupingKey
}

export const DowntimeStoppagesDetails = () => {
  const {plantId, groupType, groupId} = useParams<PathParameter>()
  if (!plantId || !groupId || !groupType) throw new Error('Missing plantId or stoppage group prop')

  const {
    data: stoppageConfig,
    isLoading,
    isError,
    refetch,
    isRefetching
  } = usePlantStoppages(plantId)

  const isEquipmentGroup =
    groupType === IssueGroupingKey.EQUIPMENT_NUMBER || groupType === IssueGroupingKey.MAIN_EQUIPMENT

  return (
    <PageContainer>
      <DataContentWrapper
        isLoading={isLoading}
        data={stoppageConfig}
        retryFunction={() => void refetch()}
        isRefetching={isRefetching}
        isError={isError}
      >
        {isEquipmentGroup ? (
          <EquipmentStoppageDetails stoppageConfig={stoppageConfig} />
        ) : (
          <DefaultStoppageDetails stoppageConfig={stoppageConfig} />
        )}
      </DataContentWrapper>
    </PageContainer>
  )
}
