import {
  ActionSource,
  EquipmentData,
  Iso8601,
  QuestionDifficulty,
  User
} from '@hconnect/common/types'

import {TasksFilterKey, TasksPageParamKey} from '../consts'

export enum TaskSource {
  EASY_POM = 'easyPom',
  COCKPIT = 'cockpit'
}

export enum TaskStatus {
  Pending = 'pending',
  Ongoing = 'ongoing',
  Done = 'done',
  Cancelled = 'cancelled',
  None = 'none',
  WaitingForApproval = 'waitingForApproval',
  Approved = 'approved',
  Rejected = 'rejected',
  WorkOrderCreated = 'workOrderCreated'
}

export type PaginationResponse<T> = {
  items: T[]
  totalItemCount: number
}

export type HProduceTask = {
  status: TaskStatus
  created: Iso8601
  description: string
  dueDate: Iso8601
  externalId: string
  id: string
  plantId: string
  source: TaskSource
  title: string
  assignees: User[]
}

export enum TasksTableSourceFilterValue {
  SHIFT = 'shift',
  POM = 'pom'
}

export enum CockpitCategory {
  Mechanical = 'mechanical',
  Electrical = 'electrical',
  Quality = 'quality',
  Process = 'process',
  Environment = 'environment',
  HealthAndSafety = 'healthAndSafety',
  Production = 'production',
  Cleaning = 'cleaning',
  Cost = 'cost',
  Other = 'other'
}

export enum TasksTableKey {
  TITLE = 'title',
  ASSIGNEE = 'assignee',
  STATUS = 'status',
  DUE_DATE = 'dueDate',
  CREATED = 'created',
  SOURCE = 'source',
  PLANT = 'plant'
}

export enum TasksSortBy {
  TITLE = 'title',
  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ASSIGNEE = 'assignee'
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export type TasksTableSortOptions = {
  sortBy?: TasksTableKey
  sortOrder?: SortOrder
  onSortChange?: (sortBy: TasksTableKey, sortOrder?: SortOrder) => void
}

export type TasksSortingOptions = {
  sortBy?: TasksSortBy
  sortOrder?: SortOrder
}

export type TaskFilterDto = {
  title?: string
  dueDateFrom?: Iso8601
  dueDateTo?: Iso8601
  statuses?: TaskStatus[]
  categories?: CockpitCategory[]
  priorities?: number[]
  assigneeIds?: string[]
  plantIds?: string[]
  processStages?: string[]
  mainEquipmentId?: Pick<EquipmentData, 'id' | 'idType'>
  equipmentId?: Pick<EquipmentData, 'id' | 'idType'>
  actionSources?: ActionSource[]
  sectionIds?: string[]
  topicIds?: string[]
  questionIds?: string[]
  questionDifficulties?: QuestionDifficulty[]
}

export type TasksFiltersState = {
  [TasksFilterKey.TITLE]?: string
  [TasksFilterKey.DUE_DATE_RANGE]?: string
  [TasksFilterKey.STATUSES]?: TaskStatus[]
  [TasksFilterKey.ASSIGNEE]?: string
  [TasksFilterKey.CATEGORIES]?: CockpitCategory[]
  [TasksFilterKey.PRIORITIES]?: number[]
  [TasksFilterKey.PLANT]?: string
  [TasksFilterKey.PROCESS_STAGES]?: string[]
  [TasksFilterKey.EQUIPMENT]?: EquipmentData
  [TasksFilterKey.MAIN_EQUIPMENT]?: EquipmentData
  [TasksFilterKey.POM_ACTION_SOURCES]?: ActionSource[]
  [TasksFilterKey.POM_SECTIONS]?: string[]
  [TasksFilterKey.POM_TOPICS]?: string[]
  [TasksFilterKey.POM_QUESTIONS]?: string[]
  [TasksFilterKey.POM_QUESTION_CATEGORIES]?: QuestionDifficulty[]
}

type TasksQuickFiltersState = {
  [TasksPageParamKey.ASSIGNED_TO_ME]?: boolean
  [TasksPageParamKey.SOURCES]?: TaskSource[]
}

export type TasksPageQueryParams = TasksQuickFiltersState & TasksFiltersState & TasksSortingOptions
