import {
  EventProcessStageTitle,
  EventStatusLabel,
  EventTypeLabel,
  getEquipmentLabelDetails,
  Tag,
  TaskRepetitionLabel
} from '@hconnect/common/components'
import {Task} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {DateFormat, GridSetting} from '@hconnect/uikit'
import {Grid} from '@mui/material'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {TaskDescription} from './TaskDescription'

const formatAssignees = (assignees: Task['assignees']) => {
  return assignees?.map(({name}) => name).join(', ') || '-'
}

const regularSize: GridSetting = {
  xs: 12,
  sm: 4,
  md: 6
}

type Props = {
  shiftTask: Task
  timezone: string
  plantId: string
  translationPrefix?: string
}

export const ShiftTaskDetails = ({shiftTask, translationPrefix, plantId, timezone}: Props) => {
  const {t} = useTranslation()
  const noneOfThatLabel = t(getTranslationKey('shiftEvent.action.noneOfThat', translationPrefix))
  const defaultRepetitionStartDate = useMemo(
    () => (shiftTask.dueDate ? moment(shiftTask.dueDate) : undefined),
    [shiftTask.dueDate]
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.eventType"
            translationPrefix={translationPrefix}
            data-test-id="task-details-eventType"
          >
            <EventTypeLabel eventType={shiftTask.eventType} translationPrefix={translationPrefix} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.status"
            translationPrefix={translationPrefix}
            data-test-id="task-details-status"
          >
            <EventStatusLabel status={shiftTask.status} translationPrefix={translationPrefix} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.category"
            translationPrefix={translationPrefix}
            data-test-id="task-details-category"
          >
            {shiftTask.category
              ? t(getTranslationKey(`shiftEvent.category.${shiftTask.category}`, translationPrefix))
              : noneOfThatLabel}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.processStage"
            translationPrefix={translationPrefix}
            data-test-id="task-details-processStage"
          >
            {shiftTask.processStage ? (
              <EventProcessStageTitle plantId={plantId} stage={shiftTask.processStage} />
            ) : (
              noneOfThatLabel
            )}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag
            data-test-id="task-details-mainEquipment"
            translationPrefix={translationPrefix}
            labelKey="shiftEvent.label.mainEquipment"
          >
            {getEquipmentLabelDetails(shiftTask.mainEquipment, false)}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag
            data-test-id="task-details-equipment"
            translationPrefix={translationPrefix}
            labelKey="shiftEvent.label.equipment"
          >
            {getEquipmentLabelDetails(shiftTask.equipment)}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.dueDate"
            data-test-id="task-details-dueDate"
            translationPrefix={translationPrefix}
          >
            <DateFormat date={shiftTask.dueDate} timezone={timezone} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.priority"
            translationPrefix={translationPrefix}
            data-test-id="task-details-priority"
          >
            {shiftTask.priority}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="task.label.assignee"
            data-test-id="task-details-assignee"
            translationPrefix={translationPrefix}
          >
            {formatAssignees(shiftTask.assignees)}
          </Tag>
        </Grid>
      </Grid>
      {shiftTask.repetitionInfo && (
        <Grid item xs={12}>
          <Tag labelKey="shiftEvent.label.recurring" translationPrefix={translationPrefix}>
            <TaskRepetitionLabel
              settings={shiftTask.repetitionInfo}
              defaultStartDate={defaultRepetitionStartDate}
              omitFirstOccurrence
              plantId={plantId}
              timezone={timezone}
              translationPrefix={translationPrefix}
            />
          </Tag>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tag
            labelKey="task.label.description"
            translationPrefix={translationPrefix}
            descriptionSx={{fontWeight: 400, fontSize: 14}}
            data-test-id="task-details-description"
          >
            <TaskDescription description={shiftTask.description} />
          </Tag>
        </Grid>
      </Grid>
    </>
  )
}
