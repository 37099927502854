import {
  fetchMainEquipmentByPxTrendTagSuggestion,
  getQueryKeyMainEquipmentByPxTrendTagSuggestion
} from '@hconnect/common/services'
import {
  EquipmentData,
  RunningTimeDto as RunningTime,
  StoppageDto as Stoppage,
  RunningTimeStoppage
} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {useCallback, FC, useState} from 'react'
import {useQueryClient} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {useConfigData} from '../../shared/hooks/useConfig'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {generateStoppageDefaultData} from '../helpers'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {StoppageEvent} from '../types'

import {StackedBarChart} from './charts'
import {StoppageView} from './performance/StoppageView'
import {PlantRunningTimes} from './PlantRunningTimes'

type PlantRunningTimesCardProps = {
  plantId: string
  stoppageAnalysisPath: string
  onStoppagePreviewOpen?: (stoppageId: string) => void
  onStoppageCreate?: (stoppageId: string) => void
  onStoppageEdit?: (stoppageId: string) => void
  onStoppageDelete?: (stoppageId: string) => void
  onStoppageSplit?: (stoppageId: string) => void
}

export const PlantRunningTimesCard: FC<PlantRunningTimesCardProps> = ({
  plantId,
  stoppageAnalysisPath,
  onStoppagePreviewOpen,
  onStoppageCreate,
  onStoppageEdit,
  onStoppageDelete,
  onStoppageSplit
}) => {
  const {from, to}: DateRange<Date> = usePlantTimeRange()
  const config = useConfigData()
  const timezone = usePlantTimezone()
  const queryClient = useQueryClient()
  const axiosInstance = useAxios()

  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)

  const handleStoppageSplit = (stoppage: Partial<Stoppage>) => {
    setStoppageIdToPreview(undefined)
    setStoppageToPreview(undefined)
    setShowCreateForm(true)
    setStoppageData(stoppage)
    stoppageToPreview?.id && onStoppageSplit?.(stoppageToPreview.id)
  }

  const handleClick = useCallback(
    async (rt: RunningTime, equipment?: EquipmentData, stoppage?: RunningTimeStoppage) => {
      if (stoppage?.cockpitStoppageId) {
        setStoppageIdToPreview(stoppage.cockpitStoppageId)
        onStoppagePreviewOpen?.(stoppage.cockpitStoppageId)
      } else {
        setStoppageToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          let suggestedMainEquipment: EquipmentData | undefined = undefined
          try {
            suggestedMainEquipment = await queryClient.fetchQuery({
              queryKey: getQueryKeyMainEquipmentByPxTrendTagSuggestion(plantId, equipment.id),
              queryFn: async () =>
                fetchMainEquipmentByPxTrendTagSuggestion({
                  plantId,
                  pxTrendTag: equipment.id,
                  axiosInstance
                }),
              staleTime: 1000 * 60 * 15
            })
          } catch (error) {
            console.error('Error fetching main equipment by px trend tag suggestion', error)
          }
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            equipment,
            suggestedMainEquipment,
            stoppage
          })
          setStoppageData(defaultValue)
        }
        setShowCreateForm(true)
      }
    },
    [axiosInstance, config?.defaultCurrency, onStoppagePreviewOpen, plantId, queryClient]
  )
  const getStackedBarChartComponent = useCallback(
    (equipment: EquipmentData, runningTimes: RunningTime[]) => {
      return (
        <StackedBarChart
          timeRange={{
            startDate: moment(from),
            endDate: moment(to)
          }}
          timezone={timezone}
          handleStoppageClick={(rt: RunningTime, stoppage?: RunningTimeStoppage) =>
            handleClick(rt, equipment, stoppage)
          }
          data={runningTimes}
          equipment={equipment}
          config={config}
        />
      )
    },
    [config, from, handleClick, to, timezone]
  )

  return (
    <>
      {stoppageIdToPreview || showCreateForm ? (
        <StoppageView
          plantId={plantId}
          showCreateForm={showCreateForm}
          stoppageData={stoppageData}
          stoppageToPreview={stoppageToPreview}
          setStoppageToPreview={setStoppageToPreview}
          setShowCreateForm={setShowCreateForm}
          stoppageIdToPreview={stoppageIdToPreview}
          setStoppageIdToPreview={setStoppageIdToPreview}
          handleStoppageSplit={handleStoppageSplit}
          onCreate={onStoppageCreate}
          onEdit={onStoppageEdit}
          onDelete={onStoppageDelete}
        />
      ) : (
        <PlantRunningTimes
          plantId={plantId}
          getStackedBarChartComponent={getStackedBarChartComponent}
          stoppageAnalysisPath={stoppageAnalysisPath}
        />
      )}
    </>
  )
}
