import {DateTimeFormat} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

type Props = {
  label: string
  date: string
  timezone: string
  'data-test-id'?: string
  translationPrefix?: string
}

export const DateAnnotation = ({
  label,
  date,
  timezone,
  'data-test-id': dataTestId,
  translationPrefix
}: Props) => {
  const {t} = useTranslation()

  return (
    <Typography component={'p'} gutterBottom variant="caption" data-test-id={dataTestId}>
      {label} {t(getTranslationKey('task.happenOn', translationPrefix))}{' '}
      <DateTimeFormat date={date} timezone={timezone} />
    </Typography>
  )
}
